body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    overflow: visible;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

#main-content {
    padding: 0 150px;

    @media(max-width: 768px) {
        padding: 0 75px;
    }
    @media(max-width: 480px) {
        padding: 0 25px;
    }
}

.description {
    //max-width: 768px;
}

#projects {
    a {
        color :black;
        padding: 10px;
    }
    svg {
        width: 24px;
        height: 24px;
    }
}

#has-links{
    a:hover {
        color:teal;
    }
}

#header-name {
    text-decoration: none;
    color: inherit;
    margin: 0;
}

.title {
    margin: 0;
}

.header {
    position: sticky;
    background-color: white;
    top: 0;
    transition: top 0.2s ease-in-out;
}

.header.hide {
    top: -8em;
}

#nav-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px 40px;
    box-shadow: 0 3px 5px black;
}

.nav-items {
    display: flex;
    margin: 0;
    padding: 0;
    align-items: center;
    justify-content: space-between;
    a {
        display: inline-block;
        padding: 10px;
        margin: 0 10px;
        text-decoration: none;
        color: black;
    }
    a:hover {
        color: teal;
    }
    @media (max-width: 768px) {
        display: none;
    }
}

.menu-button-container {
    display:block;
    @media (min-width: 768px) {
        display: none;
    }
}

.menu-button {
    padding: 15px;
    text-decoration: none;
    align-items: center;
    display: flex;
    position: relative;
    margin-right: -15px;
    -moz-box-pack: center;
    justify-content: center;
    -moz-box-align: center;
    z-index: 11;
    border: 0 none;
    background-color: transparent;
    color: inherit;
    text-transform: none;
    transition-timing-function: linear;
    transition-duration: 0.15s;
    transition-property: opacity, filter;
    cursor: pointer;
}

#hamburger-icon {
    display: block;
    width: 32px;
    height: 32px;
}

.burger-menu {
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-color: white;
    border: 2px solid black;
    height: 100vh;
    width: min(75vw, 400px);
    align-items: center;
    position: fixed;
    right: 0;
    top: 0;
    bottom: 0;
    padding: 50px 10px;
    box-shadow: -10px 0px 30px -15px black;
    font-size: 24px;
    z-index: 10;
}

.burger-nav {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    flex-direction: column;
    text-align: center;
    a {
        display: inline-block;
        padding: 10px 10px 40px 10px;
        text-decoration: none;
        color: black;
        position: relative;
        margin: 0 auto 0;
        width: 100%;
    }
    a:hover {
        color: teal;
    }
}

footer {
    display: block;
    width: 100%;
    align-items: center;
    padding: 10px;
    margin: 40px auto;
    p {
        padding: 10px;
        text-align: center;
        font-size: 16px;
    }
}

.links {
    display: flex;
    justify-content: space-around;
    align-items: center;
    max-width: 80px;
    padding: 5px;
    margin: 0 auto;
    bottom: 0;
    a {
        color: black;
        padding: 10px;
    }
    svg {
        width: 24px;
        height: 24px;
    }
    svg:hover {
        color:teal;
        cursor: pointer;
    }
}

.section {
    max-width: 1000px;
    padding: 100px 0;
    margin: 0 auto;
    h2 {
        padding: 8px 0;
    }
}



